import React from 'react';

const Footer = () => {
    return (
        <div className="mt-2 ">
            <div className="bg-dark py-3">
            <footer className="text-white text-center">&copy; All right reserved by Polok's Porfolio</footer>
            </div>
            
        </div>
    );
};

export default Footer;