import React from 'react';

const Blog = () => {
    return (
        <div className="text-center w-50 mx-auto ">

            <h2 className="border border-black my-5 p-2">Coming Soon</h2>
            
        </div>
    );
};

export default Blog;